import React from "react";
import './assets/css/App.css';
import './fullpage/examples.css'


export const Quote = ({text, by, source}) => (
  <figure class="quote">
    <blockquote>
      {text}
    </blockquote>
    <figcaption>
      &mdash; {by}{source ? "," : ""} <cite>{source}</cite>  
    </figcaption>
  </figure>
)

export const Section = ({children, anchor}) => (
  <div className="section" {...(anchor ? {"data-anchor": anchor} : {})}>
    <div className="App">
        <div className="slide-content">{children}</div>
    </div>
  </div>
)

export const Slide = ({children, anchor}) => (
  <div className="slide" {...(anchor ? {"data-anchor": anchor} : {})}>
      <div className="slide-content">{children}</div>
  </div>
)

const TopMenuItem = ({text, fullpageApi, id, section, slide}) => (
    //onClick={() => fullpageApi.moveTo(section, slide)}
    <li data-menuanchor={id}>
      <a href={`#${id}`}>
        {text}
      </a>
    </li>
  )
  
  
export const TopMenu = (props) => {
    let { items } = props
 
    return (
      <ul id="menu">
        {items.map((_props, i) => (
          <TopMenuItem key={i} {...props} {..._props} />
        ))}
      </ul>
    )
  }