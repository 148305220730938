import React from "react";
import logo from './assets/images/procedural_logo_strapline.svg';
import './assets/css/App.css';
import './fullpage/examples.css'


const Home = ({fullpageApi}) => (
  <div>
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>Procedural develops digital workflows to support your projects sustainability objectives.</p>
      <br/>
      <a
        className="App-link"
        onClick={() => fullpageApi.moveSectionDown()}
        href="#nextSection"
      >
        Learn more
      </a>
    </header>
  </div>
)

export default Home;