import React from "react"
import { Slide } from "./utils"
import { ContactLink } from "./Contact"

export const Consulting = (props) => {
    return (
      <Slide {...props}>
        <h1>Consulting</h1>
        <p>We offer consulting in:</p>
        <ul>
            <li>Construction energy calcultions</li>
            <li>Computational Fluid Dynamics (CFD)</li>
            <li>Life cycle analysis</li>
        </ul>
        <br />
        <p>... and more.  <ContactLink /> to get started.</p>

      </Slide>
    )
  }

export default Consulting