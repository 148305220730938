import React from "react"
import { Slide, Quote } from "./utils"

export const Products = (props) => {
    return (
      <Slide {...props}>
        <h1>Products</h1>
        <p>We develop our own tools that we use in-house.</p>
        <p>We use these tools in-house for our own consulting work, but we also expose these tools through API interfaces so that you can make use of them and build applications on top of them.</p>
        <p>We can also custom-make a tool for you; either built on top of our APIs or custom-developed from scratch for your project.</p>
      </Slide>
    )
  }
  
export const Compute = (props) => {
    return (
      <Slide {...props}>
        <h1>Compute</h1> 
        <h3>a task manager for executing tasks on cloud-computing infrastructre</h3>
  
        <Quote 
          text={(<p>We ran a project a month ago on our old computer and it took us 24 hours for one wind direction. With Procedural Compute, the same task can be done on 4 wind directions running parallel in 4-5 hours.  That is a 5 times speedup on one direction, but we get 4 directions out of Procedural Compute, so in total around a 20 times speedup!</p>)}
          by="Mathias Sønderskov Schaltz"
          source="Computational Design Lead, Henning Larsen Architects"
        />
  
        <a
          className="App-link"
          href="https://compute.procedural.build"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </Slide>
    )
  }
  
  export const Track = (props) => {
    return (
      <Slide {...props}>
        <h1>Track</h1> 
        <h3>a design, QA and environmental systems content management system.</h3>
  
        <p>Procedural Track makes gathering, review, follow-up, emailing, analysis, compilation and submission of documents for sustainability certifications easy.</p>
        <br />
        <a
          className="App-link"
          href="https://track.procedural.build"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
  
      </Slide>
    )
  }