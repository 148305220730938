import React from "react"
import { Slide } from "./utils"
import { ContactLink } from "./Contact"

export const Development = (props) => {
    return (
      <Slide {...props}>
        <h1>Software Development</h1>

        <p>We have extensive experience in software development, project management and implementation of digitisation practises and workflows.</p>
        <ul>
            <li>Javascript React</li>
            <li>API design and backend services</li>
            <li>Data management</li>
        </ul>
        <br />
        <p>... and more.  <ContactLink /> to get started.</p>

      </Slide>
    )
  }

export default Development