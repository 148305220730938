import React from "react";
import ReactFullpage from '@fullpage/react-fullpage';
import './assets/css/App.css';
import './fullpage/examples.css'
import Home from "./Home"
import { Section, TopMenu } from "./utils"
import { Products, Track, Compute } from "./Products"
import Consulting from "./Consulting"
import Contact from "./Contact"
import Development from "./Dev"

// Fullpage extensions/plugins
/*const pluginWrapper = () => {
  require('./fullpage/fullpage.scrollHorizontally.min');
};*/


const Fullpage = () => (
  <React.Fragment>
    <TopMenu  items={[
      {text: "Home", id: "page-1", section: 1},  // add slide: x to go horizontally
      {text: "Products", id: "page-2", section: 2},
      {text: "Track", id: "page-2/slide-2-1", section: 2, slide: 1},
      {text: "Compute", id: "page-2/slide-2-2", section: 2, slide: 2},
      {text: "Software Development", id: "page-3", section: 3},
      {text: "Consulting", id: "page-4", section: 4},
      {text: (<b>Contact</b>), id: "page-contact", section: 5},
    ]}/>

    <ReactFullpage
      //pluginWrapper = {pluginWrapper}

      //fullpage options
      licenseKey = {'KEY'}
      scrollingSpeed = {1000} /* Options here */
      //scrollHorizontally = {true}  /* Because we are using the extension */
      //scrollHorizontallyKey = {'KEY'}

      render={(props) => {
        let { fullpageApi } = props
        let _props = { fullpageApi }
        return (
          <ReactFullpage.Wrapper>
            <Section anchor="page-1">
              <Home {..._props}/>
            </Section>

            <Section anchor="page-2">
              <Products />
              <Track anchor="slide-2-1"/>
              <Compute anchor="slide-2-2"/>
            </Section>

            <Section anchor="page-3">
              <Development />
            </Section>

            <Section anchor="page-4">
              <Consulting />
            </Section>

            <Section anchor="page-contact">
              <Contact />
            </Section>

          </ReactFullpage.Wrapper>
        );
      }}
    />
  </React.Fragment>
);


export default Fullpage;
