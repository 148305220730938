import React from "react"
import { Slide } from "./utils"

export const ContactLink = () => (
    <a
        className="App-link"
        href="#page-contact"
    >
        Contact us
    </a>
)

const AddressCell = ({title, name, address, email, phone}) => (
    <td className="address-cell">
        <p><b>{title}</b></p>
        <p>{name}</p>
        { address ? address : null }
        { email ? (<p>{email}</p>) : null }
        { phone ? (<p>{phone}</p>) : null }
    </td>
)

export const Consulting = (props) => {
    return (
      <Slide {...props}>
        <h1>Contact </h1>

        <table width="100%">
            <thead>
                <td width="50%"></td>
                <td width="50%"></td>
            </thead>
            <tr>
                <AddressCell
                    title="Australia / Asia-Pacific"
                    name="Mark Pitman"
                    email="mark@procedural.build"
                    phone="+61 8 9467 5869"
                />
                <AddressCell
                    title="Denmark / Northern Europe / Nordic"
                    name="Mark Pitman"
                    email="mark@procedural.build"
                    phone="+45 22 11 21 23"
                />
            </tr>
            <tr>
                <AddressCell
                    title="Netherlands / Europe"
                    name="Paul Barton"
                    email="paul@procedural.build"
                />
                <AddressCell
                    title="United Kingdom"
                    name="Paul Barton"
                    email="paul@procedural.build"
                />
            </tr>
        </table>

      </Slide>
    )
  }

export default Consulting